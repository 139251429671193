import {
  Field,
  InfoLabel,
  Input,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import type { BizChatExp } from "sydneyeval-shared";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import { getJobErrorByPath } from "../../../selectors/creationError/getJobCreationError";
import { getValue } from "../../../selectors/getJobPropV2";
import { JobEnginesJsonFieldView } from "./JobEnginesJsonFieldView";

const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    columnWithSmallerGap: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      ...shorthands.gap("8px"),
    },
    experimentationFieldContainer: {
      display: "flex",
      flexDirection: "row",
      ...shorthands.gap("8px"),
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    experimentationFieldTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      width: "110px",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

type Props = {
  isControl: boolean;
  config: BizChatExp;
  path: `configs_in_json.conversations.exp_configs[${number}].sydney.variants`;
};

export const ExpPairExperimentView = observer((prop: Props) => {
  function renderBlockContent(props: Props): JSX.Element {
    const styles = useStyles();
    const configName = props.path.split(".")[4];
    const propError = getJobErrorByPath(props.path);

    return (
      <div className={styles.columnWithSmallerGap}>
        <div className={styles.experimentationFieldContainer}>
          <InfoLabel
            className={styles.experimentationFieldTitle}
            info={"The value of " + configName}
          >
            {configName}:
          </InfoLabel>
          <Field
            style={{ flexGrow: 1 }}
            validationState={propError === undefined ? "none" : "error"}
            validationMessage={propError?.message}
          >
            <Input
              aria-label={"The value of " + configName}
              size="medium"
              value={getValue(props.path)}
              onChange={(_, data) => {
                updatePropValueActionV2({
                  prop: props.path,
                  newData: data.value,
                });
              }}
            />
          </Field>
        </div>
        <div className={styles.experimentationFieldContainer}>
          <InfoLabel
            className={styles.experimentationFieldTitle}
            info={"The value of " + configName}
          >
            Plugins:
          </InfoLabel>
          <Field
            style={{ flexGrow: 1 }}
            validationState={propError === undefined ? "none" : "error"}
            validationMessage={propError?.message}
          >
            <JobEnginesJsonFieldView
              fieldInfor="The plugins in json format."
              key={`configs_in_json.conversations.exp_configs[${
                props.isControl ? 0 : 1
              }].sydney.plugins`}
              initValue={JSON.stringify(props.config?.sydney?.plugins)}
              path={`configs_in_json.conversations.exp_configs[${
                props.isControl ? 0 : 1
              }].sydney.plugins`}
              isRequired={true}
            />
          </Field>
        </div>
      </div>
    );
  }
  return renderBlockContent(prop);
});
