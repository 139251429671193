import {
  anyObject,
  array,
  bool,
  mapOf,
  num,
  object,
  optional,
  str,
} from "sydneyeval-shared";

export const Experiment = object({
  Id: str,
  Name: optional(str),
  Description: optional(str),
  AssignmentUnit: optional(str),
  Created: optional(str),
  Owners: optional(array(str)),
  ManagementGroup: optional(str),
  Extensions: optional(anyObject),
  FeatureRolloutMetadata: optional(
    object({
      Name: optional(str),
      RootExperimentId: optional(str),
    }),
  ),
});
export const Scorecard = object({
  Id: optional(str),
  Status: optional(str),
  AnalysisId: optional(str),
  AnalysisName: optional(str),
  StageId: optional(str),
  StageName: optional(str),
  StepId: optional(str),
  StepName: optional(str),
  ProgressionId: optional(str),
  ProgressionName: optional(str),
  IsProgressionActive: optional(bool),
  Created: optional(str),
  ExperimentId: optional(str),
  ExperimentName: optional(str),
  ExperimentGroup: optional(str),
  TimeRangeStart: optional(str),
  TimeRangeEnd: optional(str),
  Segments: array(
    object({
      name: optional(str),
    }),
  ),
});
export const ScorecardMetricsMeta = object({
  experimentStepId: optional(str),
  value: optional(
    array(
      object({
        controlVariant: optional(str),
        treatmentVariant: optional(str),
        segmentId: optional(str),
      }),
    ),
  ),
});
export const ScorecardMetrics = object({
  metricName: optional(str),
  metricLabel: optional(str),
  scorecardLayout: optional(str),
  scorecardLayoutTab: optional(str),
  metricCategories: optional(array(str)),
  desirableDirection: optional(str),
  rollupType: optional(str),
  controlVariant: optional(
    object({
      average: optional(num),
    }),
  ),
  treatmentVariant: optional(
    object({
      average: optional(num),
    }),
  ),
  delta: optional(
    object({
      absolute: optional(num),
      relative: optional(num),
    }),
  ),
  testResult: optional(
    object({
      pMove: optional(
        object({
          increaseProbability: optional(num),
          decreaseProbability: optional(num),
        }),
      ),
      pValue: optional(num),
    }),
  ),
});

export type Experiment = ReturnType<typeof Experiment>;
export type Scorecard = ReturnType<typeof Scorecard>;
export type ScorecardMetrics = ReturnType<typeof ScorecardMetrics>;
export type ScorecardMetricsMeta = ReturnType<typeof ScorecardMetricsMeta>;

export const ExperimentsResponse = object({
  Results: array(Experiment),
  TotalResults: optional(num),
});
export const SetupExperimentResponse = object({
  uuid: str,
});
export const ExperimentScorecardsResponse = mapOf(Scorecard);
export const ScorecardMetricsMetaResponse = ScorecardMetricsMeta;
export const ScorecardMetricsResponse = object({
  value: array(ScorecardMetrics),
  nextLink: optional(str),
});

export type ExperimentsResponse = ReturnType<typeof ExperimentsResponse>;
export type SetupExperimentResponse = ReturnType<
  typeof SetupExperimentResponse
>;
export type ExperimentScorecardsResponse = ReturnType<
  typeof ExperimentScorecardsResponse
>;
export type ScorecardMetricsMetaResponse = ReturnType<
  typeof ScorecardMetricsMeta
>;
export type ScorecardMetricsResponse = ReturnType<typeof ScorecardMetrics>;
