import type { Scorecard } from "../../../models/Experiment";

export const experimentScorecardList: Scorecard[] = [
  {
    Id: "425889385",
    Status: "Completed",
    AnalysisId: "5907856",
    AnalysisName: "EXOSubstrate - BizChat Online Metrics Kusto Evaluation (EU)",
    StageId: "1be130ed-b961-4d10-a2a0-0fd21b356d76",
    StageName: "WW",
    StepId: "8ce944bb-f7c9-429f-bf21-1f5d44e491c5",
    StepName: "50:50",
    ProgressionId: "ba227c1d-12bf-4292-9bcd-5905fbeea4d6",
    ProgressionName: "Default",
    IsProgressionActive: true,
    ExperimentId: "66c84f8b-cfee-481d-8288-09b768e3a43b",
    ExperimentName: "EnableUsageOfHighestAllowedRingForAvalon",
    Created: "2024-08-02T02:19:46.54+00:00",
    ExperimentGroup: "/msaiexp/bizchatservice",
    TimeRangeStart: "8/3/2024 08:00 +08:00",
    TimeRangeEnd: "8/6/2024 08:00 +08:00",
    Segments: [{ name: "Source_Segment" }],
  },
  {
    Id: "428220102",
    Status: "Completed",
    AnalysisId: "6305022",
    AnalysisName: "Test_Exp_Integration_DryRun_double_fork_ww_xinqia_p_v_f",
    StageId: "f64f98dc-5682-42aa-96d5-14e7b93ccf36",
    StageName: "Test_Shadow_Exp_Integration_Dry_Run WW",
    StepId: "3f68c940-b7a2-49e2-b4c8-b8476b322150",
    StepName: "0.2:3",
    ProgressionId: "0c7aa5a8-4904-4c73-8593-41f9d9ec776f",
    ProgressionName: "Default",
    IsProgressionActive: false,
    ExperimentId: "5acef55a-1517-4eb4-b703-6a2020e34ec8",
    ExperimentName: "Test_Shadow_Exp_Integration_Dry_Run",
    Created: "11/24/2024 23:59 -08:00",
    ExperimentGroup: "/msaiexp/bizchatshadow",
    TimeRangeStart: "11/21/2024 05:42 -08:00",
    TimeRangeEnd: "11/22/2024 05:48 -08:00",
    Segments: [{ name: "App" }],
  },
];
