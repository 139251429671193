import type { Experiment } from "../../../models/Experiment";
export const experimentList: Experiment[] = [
  {
    Id: "5acef55a-1517-4eb4-b703-6a2020e34ec8",
    Name: "Test_Shadow_Exp_Integration_Dry_Run",
    Description: "Test shadow exp integration dry run e2e",
    Created: "2024-10-29T19:22:01.1959092-07:00",
    Owners: ["xiandai", "xinqia"],
    ManagementGroup: "/msaiexp/bizchatshadow",
  },
  {
    Id: "66c84f8b-cfee-481d-8288-09b768e3a43b",
    Name: "EnableUsageOfHighestAllowedRingForAvalon",
    Description:
      "[Sydney][Avalon] Set HighestAllowedRing to lock scenario on the deployment ring where they are now",
    Created: "2024-06-13T20:23:07.9617593-07:00",
    Owners: ["niliao", "weiba", "lingqingshi", "jingyu", "xiandai"],
    ManagementGroup: "/msaiexp/bizchatservice",
  },
];
