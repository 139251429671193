import {
  Body1Strong,
  Button,
  Field,
  Link as FluentLink,
  makeStyles,
  Text,
  Title3,
  tokens,
} from "@fluentui/react-components";
import {
  ArrowLeft32Regular,
  Edit20Regular,
  Open20Regular,
  ReceiptSearch20Regular,
} from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import { Exp_Workspace_Id } from "../../../../constants/constants";
import type { Job } from "../../../OfflineEval/models/Job";
import { StatusTag } from "../common/StatusTag";
import { ShadowABBreadcrumb } from "../ShadowABBreadcrumb";
import { scorecardDetail } from "./__data__/ScorecardDetail";
import { ExperimentIterationDialog } from "./ExperimentIterationDialog";
import { ExperimentScorecardMetrics } from "./ExperimentScorecardMetrics";
const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    minHeight: "100%",
    height: "100%",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: "36px",
    marginBottom: "16px",
  },
  meta: {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "#FFF",
    borderRadius: "8px",
    border: "1px solid #D1D1D1",
    padding: "24px",
  },
  metaColumn: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  metaItem: {
    marginBottom: "8px",
  },
  inlineButton: {
    display: "inline-block",
    color: tokens.colorNeutralForeground2BrandHover,
  },
});

export const ExperimentScorecardDetail = observer(() => {
  const styles = useStyles();
  const [connectDialogOpen, setConnectDialogOpen] = React.useState(false);
  const [offlineJob, setOfflineJob] = React.useState<Job | undefined>();
  const onConnectChange = React.useCallback((job?: Job) => {
    if (job) {
      setOfflineJob(job);
    }
    setConnectDialogOpen(false);
  }, []);
  return (
    <>
      <ShadowABBreadcrumb
        nav={[
          { text: "Experiments", link: "/shadowab" },
          { text: scorecardDetail.ExperimentName ?? "" },
          { text: `Scorecard: ${scorecardDetail.Id}` },
        ]}
      />
      <div className={styles.root}>
        <div className={styles.title}>
          <div className="titleText">
            <Button appearance="transparent" icon={<ArrowLeft32Regular />}>
              <Title3>{`Scorecard: ${scorecardDetail.Id}`}</Title3>
            </Button>
          </div>
          <Button icon={<ReceiptSearch20Regular />}>
            Create flight review request
          </Button>
        </div>
        <div className={styles.meta}>
          <div className={styles.metaColumn}>
            <Field
              className={styles.metaItem}
              label={<Body1Strong>Scorecard status</Body1Strong>}
            >
              {scorecardDetail.Status ? (
                <StatusTag label={scorecardDetail.Status} />
              ) : (
                <></>
              )}
            </Field>
            <Field
              className={styles.metaItem}
              label={<Body1Strong>Experiment name</Body1Strong>}
            >
              {scorecardDetail.ExperimentId ? (
                <Link
                  to={`https://exp.microsoft.com/a/feature/${scorecardDetail.ExperimentId}?workspaceId=${Exp_Workspace_Id}`}
                  target="_blank"
                >
                  <FluentLink>
                    {scorecardDetail.ExperimentName}
                    <Button appearance="transparent" icon={<Open20Regular />} />
                  </FluentLink>
                </Link>
              ) : (
                <Text>{scorecardDetail.ExperimentName}</Text>
              )}
            </Field>
            <Field
              className={styles.metaItem}
              label={<Body1Strong>Experiment group</Body1Strong>}
            >
              <Text>{scorecardDetail.ExperimentGroup}</Text>
            </Field>
          </div>
          <div className={styles.metaColumn}>
            <Field
              className={styles.metaItem}
              label={<Body1Strong>Scorecard ring</Body1Strong>}
            >
              <Text>
                {scorecardDetail.StageName} {scorecardDetail.StepName}
              </Text>
            </Field>
            <Field
              className={styles.metaItem}
              label={<Body1Strong>Offline evaluation history</Body1Strong>}
            >
              <span>
                {offlineJob ? (
                  <Link to={`/detail/${offlineJob.ID}`}>
                    <FluentLink>{offlineJob.JobName}</FluentLink>
                  </Link>
                ) : (
                  <Text italic={true}>
                    No offline job connected, please select from list
                  </Text>
                )}
                <Button
                  className={styles.inlineButton}
                  appearance="transparent"
                  icon={<Edit20Regular />}
                  onClick={() => {
                    setConnectDialogOpen(true);
                  }}
                />
              </span>
            </Field>
            <Field
              className={styles.metaItem}
              label={<Body1Strong>Time range</Body1Strong>}
            >
              <Text>
                {scorecardDetail.TimeRangeStart}
                {" - "}
                {scorecardDetail.TimeRangeEnd}
              </Text>
            </Field>
          </div>
        </div>
        {scorecardDetail.Id && (
          <ExperimentScorecardMetrics scorecardId={scorecardDetail.Id} />
        )}
      </div>
      <ExperimentIterationDialog
        isOpen={connectDialogOpen}
        onDismiss={onConnectChange}
      />
    </>
  );
});
