import {
  Button,
  Combobox,
  makeStyles,
  mergeClasses,
  Option,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableSelectionCell,
  Text,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
const useStyles = makeStyles({
  listContainer: {
    minHeight: "400px",
  },
  listTable: {
    border: "1px solid #D1D1D1",
    borderRadius: "8px",
    borderCollapse: "collapse",
  },
  columnHeader: {
    backgroundColor: "#FAFAFA",
  },
  headerCell: {
    fontWeight: 600,
  },
  rowCell: {
    wordBreak: "break-word",
  },
  innerBorder: {
    border: "1px solid #D1D1D1",
  },
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "80px",
    padding: "0 10px",
  },
  paginationAction: {
    display: "flex",
    alignItems: "center",
  },
  paginationInfo: {
    display: "inline-block",
    flex: "0 0 160px",
  },
  pageSize: {
    minWidth: "82px",
    width: "96px",
  },
  pageSizeInput: {
    width: "52px",
  },
});
interface IPaginationTableProps<T> {
  header: Array<{
    key: string;
    title: string;
    width?: number;
    minWidth?: number;
    render?: (data: T) => React.ReactNode;
  }>;
  data: T[];
  keyName: string;
  options?: {
    innerBorder?: boolean;
    selection?: boolean;
    multiSelection?: boolean;
    pageSizes?: string[];
    hideTotal?: boolean;
    renderEmpty?: () => React.ReactNode;
  };
  onRowClick?: (data: T) => void;
  selectionKeys?: string[];
  pagination?: {
    totalNum: number;
    skipNum: number;
    pageSize: number;
  };
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
}
export const PaginationTable = observer(
  <T,>(props: IPaginationTableProps<T>) => {
    const styles = useStyles();
    const {
      data,
      keyName,
      pagination,
      header,
      options,
      onRowClick,
      selectionKeys,
      onPageChange,
      onPageSizeChange,
    } = props;
    const innerBorderClass = options?.innerBorder ? styles.innerBorder : "";
    const [checkedKeys, setCheckedKeys] = React.useState(
      new Set<string>(selectionKeys ?? []),
    );
    const onSelectionChange = (item: T) => {
      setCheckedKeys((prev) => {
        if (options?.multiSelection) {
          if (prev.has(String(item[keyName as keyof T]))) {
            prev.delete(String(item[keyName as keyof T]));
          } else {
            prev.add(String(item[keyName as keyof T]));
          }
        } else {
          prev.clear();
          prev.add(String(item[keyName as keyof T]));
        }
        return new Set(prev);
      });
      onRowClick?.(item);
    };

    const renderEmptyTable = () => (
      <div>
        <Text>No result matching found</Text>
      </div>
    );

    return (
      <>
        {data.length > 0 ? (
          <>
            <div className={styles.listContainer}>
              <Table className={styles.listTable}>
                <TableHeader className={styles.columnHeader}>
                  {options?.selection ? (
                    <TableSelectionCell
                      className={mergeClasses(innerBorderClass)}
                      type={options?.multiSelection ? "checkbox" : "radio"}
                      invisible
                    />
                  ) : (
                    <></>
                  )}
                  {header.map((column) => (
                    <TableHeaderCell
                      key={column.key}
                      className={mergeClasses(
                        styles.headerCell,
                        innerBorderClass,
                      )}
                      style={{
                        minWidth: column.minWidth,
                        width: column.width,
                      }}
                    >
                      {column.title}
                    </TableHeaderCell>
                  ))}
                </TableHeader>
                <TableBody>
                  {data.map((item) => (
                    <TableRow
                      key={String(item[keyName as keyof T])}
                      onClick={() => {
                        onSelectionChange?.(item);
                      }}
                    >
                      {options?.selection ? (
                        <TableSelectionCell
                          className={mergeClasses(innerBorderClass)}
                          checked={checkedKeys.has(
                            String(item[keyName as keyof T]),
                          )}
                          type={options?.multiSelection ? "checkbox" : "radio"}
                          checkboxIndicator={{ "aria-label": "Select row" }}
                        />
                      ) : (
                        <></>
                      )}
                      {header.map((column) => {
                        return (
                          <TableCell
                            key={String(column.key)}
                            className={mergeClasses(
                              styles.rowCell,
                              innerBorderClass,
                            )}
                          >
                            {column.render
                              ? column.render(item)
                              : String(item[column.key as keyof T])}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            {pagination ? (
              <div className={styles.pagination}>
                <span className={styles.paginationInfo}>
                  {options?.hideTotal ? (
                    <></>
                  ) : (
                    <>
                      {"Showing "}
                      {pagination.skipNum + 1} -{" "}
                      {Math.min(
                        pagination.skipNum + pagination.pageSize,
                        pagination.totalNum,
                      )}{" "}
                      of {pagination.totalNum}
                      {" items"}
                    </>
                  )}
                </span>
                <div className={styles.paginationAction}>
                  <Button
                    size="large"
                    appearance="transparent"
                    disabled={pagination.skipNum <= 0}
                    onClick={() => {
                      onPageChange?.(
                        Math.max(pagination.skipNum / pagination.pageSize, 1),
                      );
                    }}
                  >
                    {"< Prev"}
                  </Button>
                  <Button
                    size="large"
                    appearance="transparent"
                    disabled={
                      pagination.skipNum + pagination.pageSize >
                      pagination.totalNum
                    }
                    onClick={() => {
                      onPageChange?.(
                        Math.min(
                          pagination.skipNum / pagination.pageSize + 2,
                          Math.floor(
                            (pagination.totalNum - 1) / pagination.pageSize,
                          ) + 1,
                        ),
                      );
                    }}
                  >
                    {"Next >"}
                  </Button>
                  <Combobox
                    className={styles.pageSize}
                    input={{ className: styles.pageSizeInput }}
                    value={`${pagination.pageSize}/Page`}
                    onOptionSelect={(_e, select) => {
                      onPageSizeChange?.(Number(select.optionValue));
                    }}
                  >
                    {(options?.pageSizes ?? ["10", "20", "50"]).map(
                      (option) => (
                        <Option
                          key={option}
                          value={option}
                        >{`${option}/Page`}</Option>
                      ),
                    )}
                  </Combobox>
                </div>
                <span className={styles.paginationInfo}></span>
              </div>
            ) : (
              <></>
            )}
          </>
        ) : options?.renderEmpty ? (
          options?.renderEmpty()
        ) : (
          renderEmptyTable()
        )}
      </>
    );
  },
);
