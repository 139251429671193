import { Link as FluentLink } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link } from "react-router-dom";
import type { Scorecard } from "../../models/Experiment";
import { PaginationTable } from "../common/PaginationTable";
import { StatusTag } from "../common/StatusTag";
import { experimentScorecardList } from "./__data__/ExperimentScorecardList";
interface IExperimentTableProps {
  filter: {
    experiment?: string;
    status?: string;
    progression?: string;
    ring?: string;
    activeProgression?: boolean;
  };
}
export const ExperimentScorecardTable = observer(
  (props: IExperimentTableProps) => {
    const [totalNum, setTotalNum] = React.useState(0);
    const [skipNum, setSkipNum] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [scorecards, setScorecards] = React.useState<Scorecard[]>([]);
    const { filter } = props;

    React.useEffect(() => {
      const allScorecards = experimentScorecardList;
      const showScorecards = allScorecards
        .filter((scorecard) => {
          if (filter.status !== "All" && scorecard.Status !== filter.status) {
            return false;
          }
          if (
            filter.progression !== "All" &&
            scorecard.ProgressionName !== filter.progression
          ) {
            return false;
          }
          if (
            filter.ring !== "All" &&
            `${scorecard.StageName} ${scorecard.StepName}` !== filter.ring
          ) {
            return false;
          }
          if (
            filter.activeProgression &&
            scorecard.IsProgressionActive === false
          ) {
            return false;
          }
          return true;
        })
        .slice(skipNum, pageSize);
      setTotalNum(allScorecards.length);
      setScorecards(showScorecards);
    }, [skipNum, pageSize, filter]);

    const listProps = React.useMemo(() => {
      const header = [
        {
          key: "Id",
          title: "Scorecard ID",
          minWidth: 110,
          width: 110,
          render: (item: Scorecard) => (
            <Link to={`/shadowabscorecard/${item.Id}`}>
              <FluentLink>{item.Id}</FluentLink>
            </Link>
          ),
        },
        {
          key: "Status",
          title: "Status",
          minWidth: 104,
          width: 120,
          render: (item: Scorecard) => <StatusTag label={item.Status ?? ""} />,
        },
        {
          key: "StepName",
          title: "Stage and step",
          minWidth: 120,
          width: 160,
          render: (item: Scorecard) => (
            <>
              {item.StageName} {item.StepName}
            </>
          ),
        },
        {
          key: "ProgressionName",
          title: "Progression",
          minWidth: 220,
          width: 260,
          render: (item: Scorecard) => (
            <Link to={`/feature/${item.ProgressionId}`}>
              <FluentLink>{item.ProgressionName}</FluentLink>
            </Link>
          ),
        },
        {
          key: "Created",
          title: "Created at",
          minWidth: 186,
          width: 200,
        },
      ];
      return {
        header,
        data: scorecards,
        keyName: "Id",
        options: {
          innerBorder: true,
          renderEmpty: () => (
            <div>
              Scorecards will be displayed here once they are available.
            </div>
          ),
        },
        pagination: {
          totalNum,
          skipNum,
          pageSize,
        },
        onPageChange: (page: number) => {
          setSkipNum((page - 1) * pageSize);
        },
        onPageSizeChange: (result: number) => {
          setPageSize(result);
        },
      };
    }, [totalNum, skipNum, pageSize, scorecards]);

    return <PaginationTable {...listProps} />;
  },
);
