import {
  Body1Strong,
  Button,
  Combobox,
  Label,
  makeStyles,
  mergeClasses,
  Option,
  shorthands,
  Tab,
  TabList,
  tokens,
} from "@fluentui/react-components";
import {
  Add20Regular,
  Edit16Regular,
  PanelRightExpand20Regular,
} from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { ExperimentSetupDialog } from "../../../Common/components/ExperimentSetupDialog";
import type { Experiment } from "../../models/Experiment";
import type { IExperimentList } from "./ExperimentInterface";
import { experimentList } from "./__data__/ExperimentList";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  top: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    boxSizing: "border-box",
    width: "100%",
    padding: "16px",
  },
  bottom: {
    display: "flex",
    flexDirection: "column",
    flex: "0 0 90px",
    height: "90px",
    padding: "0 16px",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "16px",
    alignItems: "center",
    flex: "0 0 18px",
  },
  label: {
    marginTop: "16px",
  },
  combobox: {
    minWidth: "184px",
    width: "100%",
  },
  input: {
    minWidth: "100px",
  },
  newExperimentButton: {
    ...shorthands.padding("10px", "12px"),
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
    width: "100%",
    height: "32px",
    flex: "0 0 32px",
    marginTop: "16px",
    color: "#fff",
    marginLeft: "auto",
    fontWeight: 600,
    textAlign: "center",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    boxSizing: "border-box",
    flex: 1,
    marginTop: "16px",
    overflowX: "auto",
  },
  listItem: {
    width: "100%",
    paddingRight: "12px",
    borderRadius: "4px",
  },
  listItemActive: {
    backgroundColor: "#E6E6E6",
  },
  listItemText: {
    display: "inline-block",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});
export const ExperimentPanel = observer((props: IExperimentList) => {
  const styles = useStyles();
  const { selectedExperiment, setSelectedExperiment, setShowPanel } = props;
  const [isNewShadowABDialogOpen, setIsNewShadowABDialogOpen] =
    React.useState(false);
  const [timeRange, setTimeRange] = React.useState<number>(90);
  const [experiments, setExperiments] = React.useState<Experiment[]>([]);
  React.useEffect(() => {
    const showExperiments = experimentList.filter((exp) => {
      if (!exp.Created) {
        return false;
      }
      const diff = new Date().getTime() - new Date(exp.Created).getTime();
      return diff < timeRange * 24 * 60 * 60 * 1000;
    });
    setExperiments(showExperiments);
  }, [timeRange]);
  return (
    <>
      <div className={styles.root}>
        <div className={styles.top}>
          <Label className={styles.title}>
            <Body1Strong>Experiments</Body1Strong>
            <Button
              data-testid="hide-experiments-panel"
              appearance="transparent"
              icon={<PanelRightExpand20Regular />}
              onClick={() => setShowPanel(false)}
            />
          </Label>
          <Button
            className={styles.newExperimentButton}
            appearance="primary"
            icon={<Add20Regular />}
            onClick={() => {
              setIsNewShadowABDialogOpen(true);
            }}
          >
            New shadow AB
          </Button>
          <TabList className={styles.list} vertical>
            {experiments.map((exp) => (
              <Tab
                key={exp.Id}
                className={
                  exp.Id === selectedExperiment?.Id
                    ? mergeClasses(styles.listItem, styles.listItemActive)
                    : styles.listItem
                }
                value={exp.Id ?? ""}
                onClick={() => {
                  setSelectedExperiment(exp);
                }}
              >
                <span title={exp.Name ?? ""} className={styles.listItemText}>
                  {exp.Name ?? ""}
                </span>
              </Tab>
            ))}
          </TabList>
        </div>
        <div className={styles.bottom}>
          <Label className={styles.label}>Time range</Label>
          <Combobox
            className={styles.combobox}
            input={{ className: styles.input }}
            expandIcon={<Edit16Regular />}
            defaultValue="Last 3 months"
            onOptionSelect={(_e, data) => {
              setTimeRange(Number(data.optionValue));
            }}
          >
            <Option key="7" value="7">
              {"Last 1 week"}
            </Option>
            <Option key="30" value="30">
              {"Last 1 month"}
            </Option>
            <Option key="90" value="90">
              {"Last 3 months"}
            </Option>
            <Option key="180" value="180">
              {"Last 6 months"}
            </Option>
            <Option key="365" value="365">
              {"Last 1 year"}
            </Option>
          </Combobox>
        </div>
      </div>
      <ExperimentSetupDialog
        isOpen={isNewShadowABDialogOpen}
        onDismiss={() => {
          setIsNewShadowABDialogOpen(false);
        }}
      />
    </>
  );
});
