import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Dropdown,
  Input,
  makeStyles,
  Option,
  shorthands,
  Text,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { perfWrapper } from "../../../../helpers/telemetryHelper";

import type { JobsRouterRequestData } from "sydneyeval-shared";
import { getJobs } from "../../../../helpers/apiHelper";
import type { Job } from "../../../OfflineEval/models/Job";
import { PaginationTable } from "../common/PaginationTable";
import { StatusTag } from "../common/StatusTag";
const useStyle = makeStyles({
  root: {
    maxWidth: "800px",
  },
  dialogContent: {
    margin: "16px 0 ",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "8px",
    marginBottom: "16px",
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    width: "260px",
  },
  label: {
    ...shorthands.margin("5px", "0", "5px", "0"),
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    fontStyle: "normal",
    color: "#605E5C",
  },
  truncateCell: {
    display: "inline-block",
    width: "100%",
    overflow: "hidden",
  },
});
const Job_List_Page_Size = 10;
export const ExperimentIterationDialog = observer(
  (props: { isOpen: boolean; onDismiss: (job?: Job) => void }) => {
    const styles = useStyle();
    const { isOpen, onDismiss } = props;
    const statusDropdownOptions = React.useMemo(() => {
      return [{ key: "All", value: "All", name: "All" }];
    }, []);
    const [filterStatus, setFilterStatus] = React.useState("All");
    const [searchJobName, setSearchJobName] = React.useState("");
    const [selectedJob, setSelectedJob] = React.useState<Job | undefined>();
    const listSelected = React.useMemo(() => {
      if (selectedJob?.ID) {
        return [`${selectedJob.ID}`];
      }
      return [];
    }, [selectedJob]);
    const [connectJobs, setConnectJobs] = React.useState<Job[]>([]);
    const [skipNum, setSkipNum] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(Job_List_Page_Size);
    const [totalNum, setTotalNum] = React.useState(0);
    const handleConnectOffline = React.useCallback(() => {
      onDismiss(selectedJob);
    }, [selectedJob, onDismiss]);
    React.useEffect(() => {
      const params: JobsRouterRequestData = { PageSize: pageSize };
      if (searchJobName) {
        params.Keyword = searchJobName;
      }
      if (filterStatus && filterStatus !== "All") {
        params.JobStatus = filterStatus;
      }
      perfWrapper(
        "LoadJobList",
        getJobs(params).then((jobs) => {
          const showJobs = jobs.slice(skipNum, pageSize);
          if (totalNum === 0) {
            setTotalNum(jobs[0]?.ID ?? 0);
          }
          setConnectJobs(showJobs);
        }),
      );
    }, [skipNum, pageSize, searchJobName, filterStatus]);
    const listProps = React.useMemo(() => {
      const header = [
        {
          key: "JobName",
          title: "Job name",
          minWidth: 300,
          width: 334,
          render: (item: Job) => (
            <Text
              className={styles.truncateCell}
              truncate
              wrap={false}
              title={item.JobName}
            >
              {item.JobName}
            </Text>
          ),
        },
        {
          key: "Status",
          title: "Job status",
          minWidth: 86,
          width: 86,
          render: (item: Job) => <StatusTag label={item.Status ?? ""} />,
        },
        {
          key: "CreatedAt",
          title: "Created at",
          minWidth: 160,
          width: 200,
        },
      ];
      return {
        header,
        data: connectJobs,
        keyName: "ID",
        options: {
          selection: true,
          pageSizes: [`${Job_List_Page_Size}`],
          hideTotal: true,
        },
        onRowClick: (item: Job) => {
          setSelectedJob(item);
        },
        pagination: {
          totalNum,
          skipNum,
          pageSize,
        },
        onPageChange: (page: number) => {
          setSkipNum((page - 1) * pageSize);
        },
        onPageSizeChange: (result: number) => {
          setPageSize(result);
        },
      };
    }, [totalNum, skipNum, pageSize, connectJobs]);
    return (
      <Dialog
        modalType="modal"
        open={isOpen}
        onOpenChange={(_, data) => {
          if (data.open === true) {
            onDismiss();
          }
        }}
      >
        <DialogSurface className={styles.root}>
          <DialogBody>
            <DialogTitle>
              {"Connect to offline evaluation job"}
              <Text block={true}>
                Please select from one of the offline evaluation jobs and
                connect to current shadow AB.
              </Text>
            </DialogTitle>
            <DialogContent className={styles.dialogContent}>
              <div className={styles.rowContainer}>
                <div className={styles.columnContainer}>
                  <label className={styles.label}>Status</label>
                  <Dropdown
                    aria-label="Status Dropdown"
                    value={filterStatus}
                    onOptionSelect={(_, data) => {
                      setFilterStatus(data.optionValue ?? "");
                    }}
                  >
                    {statusDropdownOptions.map((option) => (
                      <Option key={option.key} value={option.value}>
                        {option.name}
                      </Option>
                    ))}
                  </Dropdown>
                </div>
                <div className={styles.columnContainer}>
                  <label className={styles.label}>Status</label>
                  <Input
                    value={searchJobName}
                    onChange={(_e, data) => {
                      setSearchJobName(data.value);
                    }}
                  />
                </div>
              </div>
              <PaginationTable {...listProps} selectionKeys={listSelected} />
            </DialogContent>

            <DialogActions>
              <Button onClick={handleConnectOffline} appearance="primary">
                Connect
              </Button>
              <Button onClick={() => onDismiss()} appearance="secondary">
                Cancel
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  },
);
