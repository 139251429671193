export const formatMetricsNumber = (
  value?: number,
  options?: { percent?: boolean; sign?: boolean },
) => {
  if (value === undefined || isNaN(value)) {
    return "--";
  }
  let showValue = value;
  if (options?.percent) {
    showValue *= 100;
  }
  let result = "";
  if (showValue > 1000 || showValue < -1000) {
    result = `${(showValue / 1000).toFixed(2)}k`;
  } else if (showValue > 1 || showValue < -1) {
    result = showValue.toFixed(2);
  } else if (showValue < 1 && showValue > -1 && showValue !== 0) {
    result = showValue.toFixed(4);
  } else {
    result = showValue.toString();
  }
  if (options?.percent) {
    result = `${result}%`;
  }
  if (options?.sign && value > 0) {
    result = `+${result}`;
  }
  return result;
};
