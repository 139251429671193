import type { Scorecard } from "../../../models/Experiment";

export const scorecardDetail: Scorecard = {
  Id: "425889385",
  Status: "Completed",
  AnalysisId: "5907856",
  AnalysisName: "EXOSubstrate - BizChat Online Metrics Kusto Evaluation (EU)",
  StageId: "1be130ed-b961-4d10-a2a0-0fd21b356d76",
  StageName: "WW",
  StepId: "8ce944bb-f7c9-429f-bf21-1f5d44e491c5",
  StepName: "50:50",
  ProgressionId: "ba227c1d-12bf-4292-9bcd-5905fbeea4d6",
  ProgressionName: "Default",
  ExperimentId: "66c84f8b-cfee-481d-8288-09b768e3a43b",
  ExperimentName: "EnableUsageOfHighestAllowedRingForAvalon",
  Created: "2024-08-02T02:19:46.54+00:00",
  ExperimentGroup: "/msaiexp/bizchatservice",
  TimeRangeStart: "8/3/2024 08:00 +08:00",
  TimeRangeEnd: "8/6/2024 08:00 +08:00",
  Segments: [{ name: "Source_Segment" }],
};
