import { getMetricCategory, getMetricGroups } from "./metricsMapping";

import type { Metric } from "../models/GeneralMetricsResponse";
import type { MetricsGroup } from "./metricsMapping";

export interface ExportedMetricsData {
  segment: string;
  category: string;
  metric: string;
  group: MetricsGroup;
  control: string;
  treatment: string;
  delta: string;
  pairedN: string;
  pValue: string;
}

export interface MetricsData {
  category: string;
  categoryIndex: number;
  group: MetricsGroup;
  metric: string;
  segment: string;
  control: string;
  treatment: string;
  delta: string;
  n: string;
  droppedNarrows: string;
  pValue: string;
}

export interface MetricCategory {
  category: string;
  categoryIndex: number;
  stage: string;
}

export const convertJSONToMetricsData = (jsonList: Metric[]): MetricsData[] => {
  const sortMetricsData = (a: MetricsData, b: MetricsData): number => {
    // Sort by category first, category index smaller in the first
    // if category index is the same, then sort alphabetically by metrics_key
    if (a.categoryIndex === b.categoryIndex) {
      return a.metric.localeCompare(b.metric);
    } else {
      return a.categoryIndex - b.categoryIndex;
    }
  };

  return jsonList
    .flatMap((metric) => {
      const {
        metrics_key,
        segment,
        score_control,
        score_experiment,
        score_delta,
        p_value,
        n,
        dropped_narows,
      } = metric;

      const category = getMetricCategory(metrics_key.toLowerCase());
      const groups = getMetricGroups(metrics_key.toLowerCase());

      return groups.map((group) => ({
        category: category.category,
        categoryIndex: category.categoryIndex,
        group,
        metric: metrics_key,
        segment,
        control: score_control,
        treatment: score_experiment,
        delta: score_delta,
        n: n ?? "",
        droppedNarrows: dropped_narows ?? "",
        pValue: p_value ?? "",
      }));
    })
    .sort(sortMetricsData);
};

export const mapMetricsKey = (key: string): string => {
  switch (key) {
    case "mean_query_ndcg":
      return "ndcg";
    case "mean_query_idcg":
      return "idcg";
    case "mean_query_dcg":
      return "dcg";
    case "mean_EE-Success(RaisedBar)":
      return "ee-success";
    case "groundleo_score":
      return "groundleo_score";
    case "stewieleo_relevance":
      return "stewieleo_relevance";
    case "stewieleo_engagement":
      return "stewieleo_engagement";
    case "stewieleo_detail":
      return "stewieleo_detail";
    case "stewieleo_clarity":
      return "stewieleo_clarity";
    case "stewieleo_stewieleo_score":
      return "stewieleo_score";
    case "pileo_pileo_score":
      return "pileo_score";
    case "sydney_latency":
      return "sydney_e2e_latency";
    case "mean_query_zero_results":
      return "search-nrr";
    case "stewieleosbs_relevance":
      return "stewieleosbs_relevance";
    case "stewieleosbs_engagement":
      return "stewieleosbs_engagement";
    case "stewieleosbs_detail":
      return "stewieleosbs_detail";
    case "stewieleosbs_clarity":
      return "stewieleosbs_clarity";
    case "stewieleosbs_perceived intelligence":
      return "stewieleosbs_perceived_intelligence";
    case "stewieleosbs_stewieleosbs_score":
      return "stewieleosbs_score";
    case "sbsleo_sbsleo_score":
      return "sbsleo_score";
    case "sbsleov2_sbsleov2_score":
      return "sbsleov2_score";
    case "lmchecklist_critical":
      return "lmchecklist_critical";
    case "lmchecklist_expected":
      return "lmchecklist_expected";
    case "lmchecklist_aspirational":
      return "lmchecklist_aspirational";
    case "lmchecklist_lmchecklist_score":
      return "lmchecklist_score";
    case "tcr_score":
      return "tcr_score";
    default:
      return key;
  }
};
