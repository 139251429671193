import { makeStyles } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
import {
  getScorecardMetrics,
  getScorecardMetricsMeta,
} from "../../../../helpers/apiHelper";
import { perfWrapper } from "../../../../helpers/telemetryHelper";
import { FilterButton } from "../../../OfflineEval/components/Other/FilterButton";
import type { ScorecardMetrics } from "../../models/Experiment";
import { formatMetricsNumber } from "../../utils/format";
import { PaginationTable } from "../common/PaginationTable";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  filter: {
    display: "flex",
    justifyContent: "flex-start",
    margin: "16px 0",
    gap: "8px",
  },
  list: {
    display: "flex",
    flex: 1,
    backgroundColor: "#FFF",
    padding: "16px",
    borderRadius: "8px",
    border: "1px solid #D1D1D1",
  },
  colorGreen: {
    color: "#0E700E",
    fontWeight: 600,
  },
  colorRed: {
    color: "#B10E1C",
    fontWeight: 600,
  },
});
const Key_Metrics_Name = new Set([
  "DAU%",
  "DAUPct",
  "Conversations per UU",
  "Turns per UU",
  "Turns per Conversation",
]);
interface IScorecardMetricsProps {
  scorecardId: string;
}
interface IScorecardMetricsListItem {
  Name: string;
  Category: Array<string | undefined>;
  Rollup?: string;
  Control?: number;
  Treatment?: number;
  Delta?: number;
  DeltaPercent?: number;
  Status?: number;
  PValue?: number;
  PMove?: number;
}
export const ExperimentScorecardMetrics = observer(
  (props: IScorecardMetricsProps) => {
    const styles = useStyles();
    const { scorecardId } = props;
    const [type, setType] = React.useState<string>("key");
    const [metrics, setMetrics] = React.useState<ScorecardMetrics[]>([]);
    const [metricsMeta, setMetricsMeta] = React.useState<
      | {
          controlVariant: string;
          treatmentVariant: string;
          segmentId: string;
        }
      | undefined
    >();
    const formattedMetrics: IScorecardMetricsListItem[] = React.useMemo(() => {
      const showResults = metrics.filter((metric) => {
        if (type === "key") {
          return Key_Metrics_Name.has(metric.metricName ?? "");
        } else {
          return metric.testResult?.pValue && metric.testResult?.pValue < 0.05;
        }
      });
      const results: IScorecardMetricsListItem[] = showResults.map((metric) => {
        return {
          Key: metric.metricLabel ?? Math.random().toString(),
          Name: metric.metricName ?? "",
          Status:
            (metric.desirableDirection === "Down" ? -1 : 1) *
            (metric.delta?.absolute ?? 0),
          Category: [
            metric.scorecardLayout,
            metric.scorecardLayoutTab,
            ...(metric.metricCategories ?? []),
          ],
          Rollup: metric.rollupType,
          Control: metric.controlVariant?.average,
          Treatment: metric.treatmentVariant?.average,
          Delta: metric.delta?.absolute,
          DeltaPercent: metric.delta?.relative,
          PValue: metric.testResult?.pValue,
          PMove: Math.max(
            metric.testResult?.pMove?.increaseProbability ?? 0,
            metric.testResult?.pMove?.decreaseProbability ?? 0,
          ),
        };
      });

      return results;
    }, [type, metrics]);

    const metaParams = React.useMemo(() => {
      const query: { [key: string]: string | number | boolean } = {
        maxSegmentDepth: 2,
        standardSegmentOnly: true,
      };
      return {
        workspaceId: "f082eddd-3c30-4fa7-83e7-ae506a831a40",
        "api-version": "2024-04-01-preview",
        query,
      };
    }, []);
    React.useEffect(() => {
      if (scorecardId) {
        perfWrapper(
          "LoadExperimentScorecardMetric",
          getScorecardMetricsMeta(metaParams, scorecardId).then(
            (scorecardMetricsMeta) => {
              const { controlVariant, treatmentVariant, segmentId } =
                scorecardMetricsMeta?.value?.[0] ?? {};
              if (controlVariant && treatmentVariant && segmentId) {
                setMetricsMeta({
                  controlVariant,
                  treatmentVariant,
                  segmentId,
                });
              }
            },
          ),
        );
      }
    }, [scorecardId]);
    const metricsParams = React.useMemo(() => {
      if (!metricsMeta) {
        return undefined;
      }
      const query: { [key: string]: string | number | boolean } = {
        controlVariant: metricsMeta.controlVariant,
        treatmentVariant: metricsMeta.treatmentVariant,
        segmentId: metricsMeta.segmentId,
        // segmentId: "c30f0ff6-22b6-7df6-8f00-f1fee1347ffd",
        top: 10000,
        skip: 0,
      };
      return {
        workspaceId: "f082eddd-3c30-4fa7-83e7-ae506a831a40",
        "api-version": "2024-04-01-preview",
        query,
      };
    }, [metricsMeta]);
    React.useEffect(() => {
      if (metricsParams) {
        perfWrapper(
          "LoadExperimentScorecardMetric",
          getScorecardMetrics(metricsParams, scorecardId).then(
            (scorecardMetrics) => {
              setMetrics(scorecardMetrics.value);
            },
          ),
        );
      }
    }, [metricsParams]);

    const listProps = React.useMemo(() => {
      const header = [
        {
          key: "Name",
          title: "Metric",
          minWidth: 220,
          width: 280,
        },
        {
          key: "Control",
          title: "Control",
          minWidth: 110,
          width: 150,
          render: (item: IScorecardMetricsListItem) => (
            <>{formatMetricsNumber(item.Control)}</>
          ),
        },
        {
          key: "Treatment",
          title: "Treatment",
          minWidth: 110,
          width: 150,
          render: (item: IScorecardMetricsListItem) => (
            <>{formatMetricsNumber(item.Treatment)}</>
          ),
        },
        {
          key: "Delta",
          title: "Delta",
          minWidth: 110,
          width: 150,
          render: (item: IScorecardMetricsListItem) => {
            const colorStyle =
              (item.PValue ?? 0) >= 0.05
                ? ""
                : (item.Status ?? 0) > 0
                ? styles.colorGreen
                : styles.colorRed;
            return (
              <>
                {item.Delta && item.DeltaPercent ? (
                  <>
                    {`${formatMetricsNumber(item.Delta, { sign: true })}(`}
                    <span className={colorStyle}>
                      {`${formatMetricsNumber(item.DeltaPercent, {
                        percent: true,
                        sign: true,
                      })}`}
                    </span>
                    {`)`}
                  </>
                ) : (
                  "--"
                )}
              </>
            );
          },
        },
        {
          key: "PValue",
          title: "P-Value",
          minWidth: 110,
          width: 150,
          render: (item: IScorecardMetricsListItem) => (
            <>{formatMetricsNumber(item.PValue)}</>
          ),
        },
        {
          key: "PMove",
          title: "P-Move",
          minWidth: 110,
          width: 150,
          render: (item: IScorecardMetricsListItem) => (
            <>{formatMetricsNumber(item.PMove, { percent: true })}</>
          ),
        },
      ];
      return {
        header,
        data: formattedMetrics,
        keyName: "Key",
        options: {
          innerBorder: true,
        },
      };
    }, [formattedMetrics]);

    return (
      <div className={styles.root}>
        <div className={styles.filter}>
          <FilterButton
            value={"key"}
            selectedTab={type}
            text="Key metrics"
            activeIcon={undefined}
            inactionIcon={undefined}
            clickTab={() => setType("key")}
          />
          <FilterButton
            value={"movement"}
            selectedTab={type}
            text="Metrics with significant movements"
            activeIcon={undefined}
            inactionIcon={undefined}
            clickTab={() => setType("movement")}
          />
        </div>
        <div className={styles.list} key={type}>
          <PaginationTable {...listProps} />
        </div>
      </div>
    );
  },
);
