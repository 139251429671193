import {
  Button,
  Combobox,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  Link as FluentLink,
  Label,
  Option,
  Radio,
  RadioGroup,
  Text,
  makeStyles,
} from "@fluentui/react-components";
import { Open20Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Exp_Workspace_Id } from "../../../constants/constants";
import { experimentList } from "../../ShadowAB/components/experiments/__data__/ExperimentList";
import type { Experiment } from "../../ShadowAB/models/Experiment";
import { getUserAlias } from "../../ShadowAB/utils/account";

const useStyle = makeStyles({
  dialogContent: {
    margin: "16px 0 ",
  },
  fieldLabel: {
    fontWeight: 500,
    margin: "8px 0",
  },
  tips: {
    color: "#616161",
    fontSize: "12px",
    margin: "8px 0 16px",
  },
  longButton: {
    whiteSpace: "nowrap",
  },
});

export const ExperimentSetupDialog = observer(
  (props: { isOpen: boolean; onDismiss: () => void }) => {
    const alias = getUserAlias();
    const { isOpen, onDismiss } = props;
    const styles = useStyle();
    const [experiments, setExperiments] = React.useState<Experiment[]>([]);
    const [experimentId, setExperimentId] = React.useState("");
    const [experimentOperation, setExperimentOperation] =
      React.useState("create");
    React.useEffect(() => {
      const listExperiments = experimentList.filter(
        (exp) => exp.Owners?.includes(alias),
      );
      setExperiments(listExperiments);
    }, [alias]);
    const handleSetupExperiment = () => {
      if (experimentOperation === "create") {
        window.open(
          `https://exp.microsoft.com/a/CreateExp/SelectTemplate?workspaceId=${Exp_Workspace_Id}`,
          "_blank",
        );
      } else {
        window.open(
          `https://exp.microsoft.com/a/feature/${experimentId}?workspaceId=${Exp_Workspace_Id}`,
          "_blank",
        );
      }
    };

    return (
      <Dialog
        modalType="modal"
        open={isOpen}
        onOpenChange={(_, data) => {
          if (data.open === true) {
            onDismiss?.();
          }
        }}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle data-testid="dialog-title">
              {"New shadow AB"}
            </DialogTitle>
            <DialogContent className={styles.dialogContent}>
              <Field>
                <Label className={styles.fieldLabel}>Select experiment</Label>
                <RadioGroup
                  layout="horizontal"
                  onChange={(_, data) => setExperimentOperation(data.value)}
                >
                  <Radio
                    value="create"
                    label="Create new experiment"
                    defaultChecked={true}
                  />
                  <Radio
                    data-testid="select-existing"
                    value="exist"
                    label="Select existing experiment"
                  />
                </RadioGroup>
              </Field>
              {experimentOperation === "create" && (
                <Field className={styles.tips}>
                  <Text>
                    You'll be guided to the{" "}
                    <FluentLink
                      href={`https://exp.microsoft.com/?workspaceId=${Exp_Workspace_Id}`}
                      target="_blank"
                    >
                      Control Tower
                    </FluentLink>{" "}
                    to complete the experiment setup.
                  </Text>
                </Field>
              )}
              {experimentOperation === "exist" && (
                <>
                  <Field className={styles.tips}>
                    <Text>
                      The shadow AB will be based on an existing experiment and
                      a cloned progression. You'll be guided to the{" "}
                      <FluentLink
                        href={`https://exp.microsoft.com/?workspaceId=${Exp_Workspace_Id}`}
                        target="_blank"
                      >
                        Control Tower
                      </FluentLink>{" "}
                      to continue.
                    </Text>
                  </Field>
                  <Field label="Experiment" required={true}>
                    <Combobox
                      onOptionSelect={(_e, data) =>
                        setExperimentId(data.optionValue ?? "")
                      }
                    >
                      {experiments.map((exp) => (
                        <Option key={exp.Id} value={exp.Id}>
                          {exp.Name ?? ""}
                        </Option>
                      ))}
                    </Combobox>
                  </Field>
                </>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                className={styles.longButton}
                onClick={handleSetupExperiment}
                appearance="primary"
                icon={<Open20Regular />}
              >
                {`Go to Control Tower and ${
                  experimentOperation === "create" ? "start" : "continue"
                }`}
              </Button>
              <Button onClick={() => onDismiss()} appearance="secondary">
                Cancel
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  },
);
